var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-btn",
    {
      staticClass: "ml-4",
      attrs: {
        id: "help-icon",
        ripple: false,
        color: "variable",
        "data-qa": "header-help-btn",
        elevation: "0",
        fab: "fab",
        href: "https://docs.apolloplatform.com/en/",
        small: "",
        target: "_blank",
      },
      on: { click: _vm.helpIconClicked },
    },
    [_c("v-icon", { staticClass: "docs" }, [_vm._v("far fa-question-circle")])],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }