// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./../../../public/images/logo.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".main-header[data-v-2f029f00] {\n  z-index: 6;\n}\n.v-btn:not(.v-btn--round).v-size--default[data-v-2f029f00] {\n  font-weight: 500;\n  font-size: 12px;\n  height: auto;\n  align-self: center;\n}\n.logo a[data-v-2f029f00] {\n  display: inline-block;\n  width: 64px;\n  height: 64px;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat center center;\n}\n.header[data-v-2f029f00] {\n  font-size: 12px;\n  font-weight: 500;\n}\n.header[data-v-2f029f00] .v-toolbar__content {\n  padding: 0;\n}\n.property-name[data-v-2f029f00] {\n  color: white;\n}\n.avatar[data-v-2f029f00] .avatar__title {\n  color: white;\n  font-weight: 500;\n}\n.v-badge[data-v-2f029f00] .v-btn.v-btn--icon.v-size--default {\n  width: auto;\n  height: auto;\n  font-size: 10px;\n}\n.v-select[data-v-2f029f00] .v-select__selections {\n  display: block;\n}\n.main-menu[data-v-2f029f00] .v-icon {\n  margin-top: 2px;\n}\n[data-v-2f029f00] .v-text-field.apollo-select-label .v-label {\n  margin-top: 2px !important;\n}", ""]);
// Exports
module.exports = exports;
