var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-navigation-drawer",
    {
      staticClass: "navigation",
      attrs: {
        width: _vm.disabled ? 64 : 256,
        app: "",
        clipped: "",
        "expand-on-hover": "",
        left: "",
        "mini-variant-width": "64",
        permanent: "",
      },
    },
    [
      !_vm.disabled
        ? _c(
            "v-list",
            [
              _c(
                "v-list-item-group",
                { attrs: { value: _vm.selectedTab, color: "primary" } },
                _vm._l(_vm.sideBarItems, function (item) {
                  return _c(
                    "v-list-item",
                    {
                      key: item.title,
                      staticClass: "list-item-style",
                      class: [
                        {
                          "disabled-navigation-row": !item.hasAccess,
                          "v-item--active v-list-item--active":
                            _vm.selectedTab == item.tab,
                        },
                      ],
                      attrs: {
                        disabled: item.key === "deploy" && _vm.readonlyMode,
                        ripple: item.hasAccess,
                        to: item.hasAccess ? item.to : "",
                        link: "",
                      },
                      on: {
                        click: function ($event) {
                          item.click ? item.click() : null
                        },
                        mouseleave: function ($event) {
                          return _vm.hoveredTabChange("", item.hasAccess)
                        },
                        mouseover: function ($event) {
                          return _vm.hoveredTabChange(item, item.hasAccess)
                        },
                      },
                    },
                    [
                      _c(
                        "v-list-item-icon",
                        [_c("v-icon", [_vm._v(_vm._s(item.icon))])],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        { attrs: { "align-items": "center" } },
                        [
                          _c("v-list-item-title", { staticClass: "ml-3" }, [
                            _vm._v(_vm._s(item.title)),
                          ]),
                        ],
                        1
                      ),
                      _vm.hoveredTab === item.key
                        ? _c("div", { staticClass: "custom-tooltip" }, [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    item.title +
                                      " is not available in the basic plan."
                                  )
                                )
                              ),
                            ]),
                          ])
                        : _vm._e(),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }