var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app-bar",
    {
      staticClass: "header pa-0 pr-4 elevation-0 gradient-background",
      attrs: { app: "", "clipped-left": "", fixed: "" },
    },
    [
      _c("v-container", { staticClass: "pa-0", attrs: { fluid: "" } }, [
        _c(
          "div",
          { staticClass: "d-flex justify-space-between align-center" },
          [
            _c(
              "div",
              { staticClass: "header__content d-flex align-center" },
              [
                _c(
                  "div",
                  { staticClass: "logo" },
                  [_c("router-link", { attrs: { to: "/" } })],
                  1
                ),
                _vm.isAuthenticated && _vm.isSelectedProperty
                  ? _c(
                      "router-link",
                      {
                        staticClass: "pl-10 text-decoration-none",
                        attrs: { to: { name: "properties" } },
                      },
                      [
                        _c(
                          "v-icon",
                          { attrs: { color: "primary", small: "" } },
                          [_vm._v("fa-chevron-left")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.isAuthenticated && _vm.isSelectedProperty && _vm.property
                  ? _c(
                      "div",
                      { staticClass: "property-name pl-10" },
                      [
                        _c("span", { staticClass: "text--label" }, [
                          _vm._v(_vm._s(_vm.$t("Property: "))),
                        ]),
                        _c(
                          "v-tooltip",
                          {
                            attrs: { bottom: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function (ref) {
                                    var on = ref.on
                                    var attrs = ref.attrs
                                    return [
                                      _c(
                                        "span",
                                        _vm._g(
                                          _vm._b(
                                            { staticClass: "ml-2" },
                                            "span",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [_vm._v(_vm._s(_vm.propertyName))]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3003367552
                            ),
                          },
                          [_c("span", [_vm._v(_vm._s(_vm.property.name))])]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.showPropertySettingsButton
                  ? _c(
                      "v-btn",
                      {
                        staticClass: "ml-3",
                        attrs: {
                          ripple: false,
                          color: "variable",
                          "data-qa": "button-property-settings",
                          elevation: "0",
                          fab: "fab",
                          small: "",
                        },
                        on: { click: _vm.onPropertySettingsClicked },
                      },
                      [_c("v-icon", [_vm._v("fa-cog")])],
                      1
                    )
                  : _vm._e(),
                _vm.isAuthenticated && !!_vm.organizations.length
                  ? _c(
                      "div",
                      { staticClass: "organizations pl-4" },
                      [
                        _c("v-select", {
                          staticClass:
                            "apollo-select-label apollo-select-label--secondary apollo-select-small",
                          attrs: {
                            items: _vm.organizations,
                            label: _vm.$t("Organizations"),
                            "menu-props": { bottom: true, offsetY: true },
                            value: _vm.selectedOrganizationId,
                            "append-icon": "fa-chevron-down",
                            "data-qa": "header-organizations-dropdown",
                            "hide-details": "auto",
                            "item-text": "name",
                            "item-value": "id",
                          },
                          on: { change: _vm.onOrganizationChange },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.isAuthenticated && _vm.agencies.length > 1
                  ? _c(
                      "div",
                      { staticClass: "agencies pl-4" },
                      [
                        _c("v-select", {
                          staticClass:
                            "apollo-select-label apollo-select-label--secondary apollo-select-small",
                          attrs: {
                            items: _vm.agencies,
                            label: _vm.$t("Agencies"),
                            "menu-props": { bottom: true, offsetY: true },
                            value: _vm.selectedAgencyId,
                            "append-icon": "fa-chevron-down",
                            "data-qa": "header-agencies-dropdown",
                            "hide-details": "auto",
                            "item-text": "name",
                            "item-value": "id",
                          },
                          on: { change: _vm.onAgencyChange },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "header__aside d-flex align-center justify-end" },
              [
                _vm.isAuthenticated && _vm.personName
                  ? _c(
                      "div",
                      { staticClass: "avatar mr-10 d-flex align-center" },
                      [
                        _c(
                          "v-avatar",
                          { attrs: { color: "variable", size: "40" } },
                          [
                            _c(
                              "span",
                              { staticClass: "white--text subtitle-2" },
                              [_vm._v(_vm._s(_vm.personInitials))]
                            ),
                          ]
                        ),
                        _c("div", [
                          _c("h4", { staticClass: "avatar__title mb-0 ml-2" }, [
                            _vm._v(_vm._s(_vm.personName)),
                          ]),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.isAuthenticated
                  ? _c(
                      "div",
                      { staticClass: "main-menu ml-8" },
                      [
                        _c(
                          "v-menu",
                          {
                            attrs: { bottom: "", left: "", "z-index": "9999" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function (ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            attrs: {
                                              color: "variable",
                                              "data-qa":
                                                "header-waffle-menu-btn",
                                              elevation: "0",
                                              fab: "",
                                              small: "",
                                            },
                                          },
                                          on
                                        ),
                                        [
                                          _c(
                                            "v-icon",
                                            { staticClass: "menu-icon" },
                                            [_vm._v("far fa-th")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2098664588
                            ),
                          },
                          [
                            _c(
                              "v-list",
                              [
                                _c(
                                  "v-list-item-group",
                                  { attrs: { color: "primary" } },
                                  _vm._l(_vm.menuItems, function (item) {
                                    return item.visible
                                      ? _c(
                                          "v-list-item",
                                          {
                                            key: item.title,
                                            attrs: {
                                              "data-qa":
                                                "header-waffle-menu-" +
                                                item.title,
                                              href: item.href,
                                              ripple: false,
                                              target: item.href
                                                ? "_blank"
                                                : null,
                                              to: item.to,
                                            },
                                            on: {
                                              click: function ($event) {
                                                item.click ? item.click() : null
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "v-list-item__icon menu-icon",
                                              },
                                              [
                                                _c("v-icon", [
                                                  _vm._v(_vm._s(item.icon)),
                                                ]),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "v-list-item__content",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "v-list-item__title",
                                                  },
                                                  [_vm._v(_vm._s(item.title))]
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _vm._e()
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            ),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }