var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c("help-icon"),
      _c("div", { attrs: { id: "intercom-icon" } }),
      _c("div", { attrs: { id: "side-dialog-container" } }),
      _c("app-header", {
        attrs: {
          agencies: _vm.agencies,
          isAgencyAdmin: _vm.isAgencyAdmin,
          isAuthenticated: _vm.isAuthenticated,
          isOrganizationAdmin: _vm.isOrganizationAdmin,
          isSelectedProperty: _vm.isSelectedProperty,
          isSystemAdmin: _vm.isSystemAdmin,
          menuItems: _vm.menuItems,
          organizations: _vm.filteredOrganizations,
          property: _vm.selectedProperty,
          selectedAgencyId: _vm.selectedAgencyId,
          selectedOrganizationId: _vm.selectedOrganizationId,
          user: _vm.user,
        },
        on: {
          agencyChanged: _vm.onAgencyChange,
          organizationChanged: _vm.onOrganizationChange,
          propertySettingsClicked: _vm.propertySettingsClicked,
        },
      }),
      _vm.isAuthenticated
        ? _c("property-navigation", {
            attrs: {
              disabled: !_vm.isSelectedProperty,
              isAuthenticated: _vm.isAuthenticated,
              propertySetupStatus: _vm.propertyStatus,
              selectedTab: _vm.selectedTab,
              sideBarItems: _vm.sideBarItems,
            },
          })
        : _vm._e(),
      _c(
        "app-content",
        [
          _c(
            "app-container",
            { staticClass: "pa-10", attrs: { fluid: "" } },
            [
              _c(
                "v-dialog",
                {
                  attrs: { persistent: "", width: "600" },
                  model: {
                    value: _vm.isUIDisabled,
                    callback: function ($$v) {
                      _vm.isUIDisabled = $$v
                    },
                    expression: "isUIDisabled",
                  },
                },
                [
                  _c(
                    "v-card",
                    { attrs: { height: "200" } },
                    [
                      _c(
                        "v-card-title",
                        {
                          staticClass:
                            "justify-center text-h2 text-uppercase cyan--text font-weight-medium",
                        },
                        [_vm._v(_vm._s(_vm.$t("Scheduled Maintenance")))]
                      ),
                      _c("v-card-text", [
                        _c("div", { staticClass: "modal-icon" }, [
                          _c("span", [
                            _c("i", {
                              staticClass:
                                "fa-solid fa-screwdriver-wrench fa-2xl",
                            }),
                          ]),
                        ]),
                        _c("div", { staticClass: "modal-text" }, [
                          _c("p", [
                            _vm._v(
                              "Apollo is currently down for scheduled maintenance. This won't take long, so please check back and refresh your browser in a few minutes."
                            ),
                          ]),
                          _c("p", [_vm._v("Thank you for your patience.")]),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._l(_vm.alerts, function (item, i) {
                return _c(
                  "v-alert",
                  {
                    key: i + "alert",
                    attrs: {
                      color: item.type,
                      icon: item.icon || "fa-info-circle",
                      "close-text": "Close",
                      dismissible: "",
                    },
                    model: {
                      value: item.visible,
                      callback: function ($$v) {
                        _vm.$set(item, "visible", $$v)
                      },
                      expression: "item.visible",
                    },
                  },
                  [_vm._v(_vm._s(item.text))]
                )
              }),
              _vm._l(_vm.notifications, function (item, i) {
                return _c(
                  "v-snackbar",
                  {
                    key: i + "notification",
                    attrs: {
                      bottom: item.bottom,
                      color: item.type,
                      left: item.left,
                      right: item.right,
                      timeout: item.timeout || 5000,
                      top: item.top,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "action",
                          fn: function (ref) {
                            var attrs = ref.attrs
                            return [
                              _c(
                                "v-btn",
                                _vm._b(
                                  {
                                    attrs: { icon: "" },
                                    on: {
                                      click: function ($event) {
                                        item.visible = false
                                      },
                                    },
                                  },
                                  "v-btn",
                                  attrs,
                                  false
                                ),
                                [
                                  _c("v-icon", { attrs: { color: "white" } }, [
                                    _vm._v("fa-times"),
                                  ]),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                    model: {
                      value: item.visible,
                      callback: function ($$v) {
                        _vm.$set(item, "visible", $$v)
                      },
                      expression: "item.visible",
                    },
                  },
                  [_vm._v(_vm._s(item.text))]
                )
              }),
              _c("router-view"),
            ],
            2
          ),
        ],
        1
      ),
      _c("div", { attrs: { id: "side-dialog-container" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }